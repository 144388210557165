<template>
  <v-app-bar class="app-header px-0" flat app>
    <v-row class="app-header__content" align="center" justify="center" no-gutters>
      <v-col v-if="canShowBackButton" class="col-5 d-flex align-center justify-start">
        <div class="d-none d-sm-flex flex-column ml-5">
          <div class="workspace-title__headline">
            {{ $t('workspace.title') }}
          </div>

          <div class="workspace-title__title">
            {{ workspaceTitle }}
          </div>
        </div>
      </v-col>

      <v-col class="col-2 d-flex align-center justify-center">
        <v-btn
          icon
          @click="
            $router.push({
              name: 'dashboard',
              params: {
                workspace_id: $route.params.workspaceId,
              },
            })
          "
        >
          <v-img class="shrink" :src="logoSource" transition="scale-transition" contain />
        </v-btn>
      </v-col>

      <v-col v-if="canShowSaveButton" class="col-5 d-flex align-center justify-end">
        <v-btn
          class="subtitle-1 font-weight-regular"
          color="primary"
          :loading="saving"
          text
          @click="saveCurrentState"
        >
          <span class="save--btn">
            {{ $t(`general.${$vuetify.breakpoint.mobile ? 'save' : 'save_and_close'}`) }}
          </span>

          <v-icon color="#7031B4" size="24" right>
            {{ `mdi-${$vuetify.breakpoint.mobile ? 'content-save-outline' : 'close'}` }}
          </v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </v-app-bar>
</template>

<script>
import { defineComponent } from 'vue';
import { getErrorResponseMessage } from '@/common/reusable/errorResponse';
import { useContractStore } from '@/features/contracts/stores/contract';
import { useSignIdentitiesStore } from '@/features/contracts/stores/signIdentities';
import { useWorkspaceStore } from '@/stores/workspace';

export default defineComponent({
  name: 'CreateContractsCollectionAppBar',
  setup() {
    const contractStore = useContractStore();
    const signIdentitiesStore = useSignIdentitiesStore();
    const workspaceStore = useWorkspaceStore();

    return {
      contractStore,
      signIdentitiesStore,
      workspaceStore,
    };
  },
  data() {
    return {
      saving: false,
    };
  },
  computed: {
    canShowBackButton() {
      return 'undefined' !== typeof this.$route?.meta?.backButton
        ? this.$route?.meta?.backButton
        : true;
    },
    canShowSaveButton() {
      return 'undefined' !== typeof this.$route?.meta?.saveButton
        ? this.$route?.meta?.saveButton
        : true;
    },
    workspaceTitle() {
      return this.workspaceStore?.workspaceTitle;
    },
    logoSource() {
      return require('@/layouts/createContractsCollection/assets/signi_icon.svg');
    },
  },
  methods: {
    saveCurrentState() {
      this.saving = true;
      this.contractStore
        .updateContractData({
          contractId: this.contractStore.mainContractId,
          data: Object.assign({}, this.contractStore.contract, {
            one_device: this.contractStore.contract?.one_device ? 1 : 0,
            parties: this.signIdentitiesStore.signIdentities,
          }),
        })
        .then(() => {
          this.$router.push({
            name: 'dashboard',
            params: {
              workspace_id: this.workspaceStore.workspaceId,
            },
          });
        })
        .catch((err) => {
          this.$notification.error(getErrorResponseMessage(err?.response?.data));
        })
        .finally(() => {
          this.saving = false;
        });
    },
  },
});
</script>

<style lang="scss" scoped>
.app-header {
  background-color: #f2f6fc !important;
  border-bottom: 1px solid #d4dff0;

  &__content {
    max-width: 1440px;
    margin: 0 auto;
  }
}

.workspace-title {
  &__headline {
    height: 15px;
    color: #868e96;
    font-size: 12px;
    letter-spacing: 0;
    line-height: 15px;
  }

  &__title {
    height: 17px;
    color: #495057;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 17px;
  }
}

.back--btn {
  height: 20px;
  color: #424242;
  font-size: 16px;
  letter-spacing: 0;
}

.save--btn {
  height: 20px;
  color: #424242;
  font-size: 16px;
  letter-spacing: 0;
}
</style>
