<template>
  <v-container class="fill-height align-content-center justify-center">
    <div class="page-loader__wrapper">
      <div class="page-loader__loader"></div>
    </div>
  </v-container>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'PageLoader',
});
</script>

<style lang="scss" scoped>
@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform:rotate(360deg);
  }
}

.page-loader {
  &__wrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
  }

  &__loader {
    width: 5rem;
    height: 5rem;
    background: url(/images/loader.svg) no-repeat center;
    background-size: contain;
    animation: spin 4s linear infinite;
  }
}
</style>
